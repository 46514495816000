import React, { useRef, useState, useEffect } from 'react';
import { getBase64, pageReload, triggerAlert } from '../../utils/CommonFunctions';
import { useForm, Controller } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { commonComposeAPI, commonVideoAPI, SubUserList, commonStoryAPI, subUserData } from '../../utils/ApiClient';
import './Compose.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShowComposeModal from './ShowComposeModal';
import { truncateName } from '../../utils/CommonFunctions'
import SocialContent from './Preview';
import Loader from '../../common/components/Loader';

export default function Compose() {
    const fileInputRef = useRef(null);
    const textareaRef = useRef(null); // Reference to the textarea

    const fileVideoRef = useRef(null);
    const [imgUpload, setImgUpload] = useState(null);
    const [showComposeModal, setShowComposeModal] = useState(false);
    const [videoUpload, setVideoUpload] = useState(null);
    const [userData, setUserData] = useState([]);
    const [storyUpload, setStoryUpload] = useState(null);
    const [selectedPostType, setSelectedPostType] = useState('post'); // Initialize to 'post'
    const [captionText, setCaptionText] = useState(''); // Start with an empty string
    const [showMediaModal, setShowMediaModal] = useState(false); // New state for media modal
    const [isLoading, setIsLoading] = useState(false);
    const [subUser, setSubUser] = useState([]);

    const [alertMessage, setAlertMessage] = useState(""); // Initializing state for alertMessage
    const { register, handleSubmit, formState: { errors }, reset, setValue, setError, control, clearErrors, watch } = useForm({
        defaultValues: {
            post_type: 'post', // Set default value for post_type
        }
    });
    const [mediaFromCompose, setMediaFromCompose] = useState(null);
    const handleShowComposeModal = () => {
        setShowComposeModal(true);
    };

    const handlePostTypeChange = (e) => {
        const value = e.target.value;
        setSelectedPostType(value); // Update the selected post type

        reset({
            post_type: value, // Set the selected value as the new default
        });
        setImgUpload(null); // Clear uploaded image
        setVideoUpload(null); // Clear uploaded video
        setCaptionText(''); // Clear caption text
        setStoryUpload(null); // Clear story upload if needed
        clearErrors(); // Clear validation errors
        setAlertMessage(""); // Clear any existing alert messages when post type changes

    };

    const handleComposeClose = () => {
        setShowComposeModal(false);
        reset(); // Reset form when closing modal
        setImgUpload(null); // Clear uploaded image
        setVideoUpload(null); // Clear uploaded video
        setAlertMessage(""); // Clear alert messages when closing the modal
    };

    const handleDateChange = (date, field) => {
        const today = new Date();
        // Clear errors initially
        clearErrors('when_to_post');

        // Check if the selected date is before today
        if (date && date < today) {
            setError('when_to_post', {
                type: 'manual',
                message: 'Date and time must be today or later.',
            });
        } else {
            field.onChange(date ? date : null); // Update the field if valid
        }
    };

    const handleFileChange = async (e, type) => {

        const file = e.target.files[0];
        if (!file) return;

        let items = {};
        let contentType = type === "video" ? "reel" : selectedPostType; // Set content type based on type or postType

        // Clear previous messages
        setAlertMessage(null);
        e.target.value = '';

        // Size restrictions based on type
        const sizeLimit = type === "video" ? 650 * 1024 * 1024 : 30 * 1024 * 1024;
        if (file.size > sizeLimit) {
            setAlertMessage({
                type: "warning",
                message: `File size should not exceed ${type === "video" ? "650MB" : "30MB"}.`
            });
            return;
        }

        // Handle PDF restriction
        if (contentType === "post" && file.type === "application/pdf") {
            setAlertMessage({ type: "warning", message: "PDF files are not allowed." });
            return;
        }

        // Type-specific validations
        const isImage = file.type.startsWith("image/");
        const isVideo = file.type.startsWith("video/");
        const allowedVideoTypes = ["video/mp4", "video/gif", "video/mov"];

        if (type === "video" && isVideo && allowedVideoTypes.includes(file.type)) {
            const video = document.createElement("video");
            video.src = URL.createObjectURL(file);

            // video.onloadedmetadata = async () => {
            //     const aspectRatio = video.videoWidth / video.videoHeight;
            //     const minAspectRatio = 1.91 / 1;
            //     const maxAspectRatio = 9 / 16;
            //     console.log("aspectRatio", aspectRatio)
            //     if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio
            //         // || video.duration > 90
            //     ) {
            //         setAlertMessage({
            //             type: "warning",
            //             message: "Aspect ratio must be between 1.91:1 and 9:16."
            //         });
            //         return;
            //     }
            //     processFile(file, items, contentType);
            // };
            // video.onerror = () => {
            //     setAlertMessage({ type: "warning", message: "Error loading video. Please try another file." });
            // };
            video.onloadedmetadata = async () => {
                const minWidth = 320;  // Minimum width in pixels
                const maxWidth = 1920; // Maximum width in pixels
                const minHeight = 180; // Minimum height in pixels
                const maxHeight = 1080; // Maximum height in pixels

                // console.log("Video Dimensions:", video.videoWidth, video.videoHeight);

                if (
                    video.videoWidth < minWidth || video.videoWidth > maxWidth ||
                    video.videoHeight < minHeight || video.videoHeight > maxHeight
                    // || video.duration > 90
                ) {
                    setAlertMessage({
                        type: "warning",
                        message: `Video dimensions must be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} pixels.`
                    });
                    return;
                }

                processFile(file, items, contentType);
            };

            video.onerror = () => {
                setAlertMessage({
                    type: "warning",
                    message: "Error loading video. Please try another file."
                });
            };
        } else if (type === "image" && isImage) {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = async () => {
                const isValidResolution = contentType === "story"
                    ? img.width <= 1080 && img.height <= 1920
                    : img.width >= 320 && img.width <= 1080;

                if (!isValidResolution) {
                    setAlertMessage({
                        type: "warning",
                        message: contentType === "story"
                            ? "Image resolution must be 1080 x 1920 pixels or smaller."
                            : "Image width must be between 320 and 1080 pixels."
                    });
                    return;
                }
                processFile(file, items, contentType);
            };
        } else {
            setAlertMessage({ type: "warning", message: "Invalid file type. Please upload an image or video." });
        }
    };

    const processFile = async (file, items, contentType) => {
        try {
            const base64 = await getBase64(file);
            items = {
                file_name: file.name,
                file_type: file.name.split(".").pop(),
                file_size: file.size,
                file: base64.substring(base64.indexOf(",") + 1),
                preview: base64,
                content_type: contentType
            };

            if (contentType === "reel") {
                setVideoUpload(items);
            } else {
                setImgUpload(items);
                if (contentType === "story") setStoryUpload(items);
            }
            setAlertMessage(null);
        } catch (error) {
            console.error("Error converting file to base64:", error);
            setAlertMessage({ type: "warning", message: "Failed to process the file." });
        }
    };

    const fetchSubUser = async () => {
        const user_id = '17841464028464664'; // Replace with the actual user ID
        const access_token = 'EAAFZBkHk1F7cBOxNdLxwae8IdFRBso26psWeCxdNRqY2tz2H5q8hQj7RLWUEt2dq3vwveF0ZAAx9QEQyZBa29vKK5kuJF4gHe4CKv2ZA0ZAW7v2gorLcy8UTSJMm2ebtBZAxAfhN6KjxE1FREwFrsN6tQLsVrjoDXWhJlrFGd5jYvZAj54SRdXL1rpwQTm5qgWgSW2x7xKL'; // Replace with the actual access token

        try {
            const params = {
                access_token: access_token,
            };

            // Pass user_id and params to the API function
            const response = await SubUserList(user_id, params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const data = response_data.results; // Use response_data to access results
                setUserData(data);
            } else {
                setUserData([]);
            }
        } catch (error) {
            const response_data = error?.response?.data;
            console.error('Error fetching sub-user data:', response_data?.message || 'Something went wrong!');
        }
    };

    useEffect(() => {
        fetchSubUser(); // Initial fetch with user_id and access_token
    }, []);

    // const commonCompose = async (data) => {
    //     const params = {
    //         access_token: "EAAFZBkHk1F7cBOxNdLxwae8IdFRBso26psWeCxdNRqY2tz2H5q8hQj7RLWUEt2dq3vwveF0ZAAx9QEQyZBa29vKK5kuJF4gHe4CKv2ZA0ZAW7v2gorLcy8UTSJMm2ebtBZAxAfhN6KjxE1FREwFrsN6tQLsVrjoDXWhJlrFGd5jYvZAj54SRdXL1rpwQTm5qgWgSW2x7xKL",
    //         business_id: 17841464028464664
    //     };

    //     try {
    //         const postType = data.post_type;

    //         if (postType === 'post' && imgUpload) {
    //             data.image_data = imgUpload.file;
    //             const response = await commonComposeAPI(params, data);
    //             if (response.data.error_code === 200) {
    //                 triggerAlert('success', 'Success', 'Posted successfully!');
    //                 handleComposeClose(); // Close the modal after success message
    //                 pageReload(); // Reload the page after a successful post
    //             } else {
    //                 triggerAlert('error', 'Error', 'Failed to post image.');
    //             }
    //         } else if (postType === 'reel' && videoUpload) {
    //             data.video_data = videoUpload.file;
    //             const response = await commonVideoAPI(params, data);
    //             if (response.data.error_code === 201) {
    //                 triggerAlert('success', 'Success', 'Reel stored successfully!');
    //                 handleComposeClose(); // Close the modal after success message
    //                 pageReload(); // Reload the page after a successful post
    //             } else {
    //                 triggerAlert('error', 'Error', 'Failed to post video.');
    //             }
    //         } else if (postType === 'story' && storyUpload) {
    //             data.image_url = storyUpload.file;
    //             const response = await commonStoryAPI(params, data);

    //             if (response.data.error_code === 200) {
    //                 triggerAlert('success', 'Success', 'Story Posted successfully!');
    //                 handleComposeClose(); // Close the modal after success message
    //                 pageReload(); // Reload the page after a successful post
    //             } else if (postType === 'story' && storyUpload) {
    //                 data.image_url = storyUpload.file;
    //                 const response = await commonStoryAPI(params, data);

    //                 // Combining success messages
    //                 let successMessage = '';

    //                 if (response.data.error_code === 200) {
    //                     successMessage = 'Story Posted successfully!';
    //                 } else if (response.data.error_code === 201) {
    //                     successMessage = 'Image stored in container successfully!';
    //                 }

    //                 if (successMessage) {
    //                     triggerAlert('success', 'Success', successMessage);
    //                     handleComposeClose(); // Close the modal after success message
    //                     pageReload(); // Reload the page after a successful post
    //                 } else {
    //                     triggerAlert('error', 'Error', 'Failed to post story.');
    //                 }
    //             }
    //             else { // Handle any other error codes
    //                 triggerAlert('error', 'Error', 'Failed to post story.');
    //             }
    //         }
    //         else {
    //             triggerAlert('error', 'No File', 'Please upload an image or video.');
    //         }
    //     } catch (error) {
    //         triggerAlert('error', 'Oops...', 'Something went wrong..');
    //     }
    // };

    const commonCompose = async (data) => {
        setIsLoading(true);
        const params = {
            access_token: "EAAFZBkHk1F7cBOxNdLxwae8IdFRBso26psWeCxdNRqY2tz2H5q8hQj7RLWUEt2dq3vwveF0ZAAx9QEQyZBa29vKK5kuJF4gHe4CKv2ZA0ZAW7v2gorLcy8UTSJMm2ebtBZAxAfhN6KjxE1FREwFrsN6tQLsVrjoDXWhJlrFGd5jYvZAj54SRdXL1rpwQTm5qgWgSW2x7xKL",
            business_id: 17841464028464664
        };

        try {
            const postType = data.post_type;

            if (postType === 'post' && imgUpload) {
                data.image_data = imgUpload.file;
                const response = await commonComposeAPI(params, data);

                if (response.data.error_code === 200 || response.data.error_code === 201) {
                    const successMessage = response.data.error_code === 200
                        ? 'Image Posted successfully!'
                        : 'Image stored in container successfully!';
                    triggerAlert('success', 'Success', successMessage);
                    handleComposeClose();
                    pageReload();
                } else {
                    triggerAlert('error', 'Error', 'Failed to post image.');
                    handleComposeClose();
                }

            } else if (postType === 'reel' && videoUpload) {
                data.video_data = videoUpload.file;
                const response = await commonVideoAPI(params, data);
                if (response.data.error_code === 200 || response.data.error_code === 201) {
                    const successMessage = response.data.error_code === 200
                        ? 'Reel Posted successfully!'
                        : 'Reel stored in container successfully!';
                    triggerAlert('success', 'Success', successMessage);
                    handleComposeClose();
                    pageReload();
                } else {
                    triggerAlert('error', 'Error', 'Failed to post image.');
                    handleComposeClose();
                }

            } else if (postType === 'story' && storyUpload) {
                data.image_url = storyUpload.file;
                const response = await commonStoryAPI(params, data);
                if (response.data.error_code === 200 || response.data.error_code === 201) {
                    const successMessage = response.data.error_code === 200
                        ? 'Story Posted successfully!'
                        : 'Image stored in container successfully!';
                    triggerAlert('success', 'Success', successMessage);
                    handleComposeClose();
                    pageReload();
                } else {
                    triggerAlert('error', 'Error', 'Failed to post story.');
                    handleComposeClose();
                }
            } else {
                triggerAlert('error', 'No File', 'Please upload an image or video.');
            }
        } catch (error) {
            // Handle specific 400 error response in the catch block
            if (error.response && error.response.status === 400) {
                triggerAlert('error', 'Error', 'Failed to upload the data.');
                handleComposeClose(); // Close modal on 400 error
            } else {
                // General error handling for other errors
                triggerAlert('error', 'Oops...', 'Something went wrong..');
                handleComposeClose(); // Close modal on general errors
            }
        } finally {
            setIsLoading(false);
        }
    };
    const handleVideoClick = () => {
        if (fileVideoRef.current) {
            fileVideoRef.current.click();
        }
    };

    const handleIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleMediaModalClose = () => {
        setShowMediaModal(false);
    };

    const handleAddToCompose = (mediaData) => {
        mediaData.from = "common_gallary";
        if (mediaData.type === 'image') {
            // setImgUpload({
            //     file_name: mediaData.data.title,
            //     file_type: mediaData.data.imgSrc.split('.').pop(),
            //     file_size: 0, // You can set the actual file size if available
            //     file: mediaData.data.imgSrc,
            //     preview: mediaData.data.imgSrc,
            // });
            // console.log("mediaData", mediaData)
            setMediaFromCompose(mediaData)
        } else if (mediaData.type === 'video') {
            // setVideoUpload({
            //     file_name: mediaData.data.title,
            //     file_type: mediaData.data.videoSrc.split('.').pop(),
            //     file_size: 0, // You can set the actual file size if available
            //     file: mediaData.data.videoSrc,
            //     preview: mediaData.data.videoSrc,
            // });
            setMediaFromCompose(mediaData)
        }
    };
    const fetchSubUserData = async () => {
        try {
            setIsLoading(true);
            const response = await subUserData(); // Your API call function

            if (response?.data?.results?.length) {
                setSubUser(response.data.results); // Update state with sub-user data
            }
        } catch (error) {
            console.log(error, "error");
            triggerAlert('error', '', error?.response?.data?.message || "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch sub-user data when the component mounts
    useEffect(() => {
        fetchSubUserData();
    }, []);


    const handleMediaIconClick = () => {
        setShowMediaModal(true); // Open media modal when icon is clicked
    };
    return (
        <>
            <li class="nav-item">
                <a class="d-flex align-items-center">
                    <button type="button" class="btn btn-primary" onClick={handleShowComposeModal}>
                        <i class="fa fa-pencil" aria-hidden="true"></i> Compose
                    </button>
                </a>
            </li>

            <Modal show={showComposeModal} onHide={handleComposeClose} backdrop="static" size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Compose</Modal.Title>
                </Modal.Header>
                <form id="creditCardForm"
                    className="g-3 fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container"
                    novalidate="novalidate" onSubmit={handleSubmit(commonCompose)}>
                    <Modal.Body>
                        {isLoading && (
                            <div className='loader-overlay text-white'>
                                <Loader />
                            </div>
                        )}
                        <div className="row">
                            <div className="form-group col-md-4">
                                <label className="form-label">Post Type</label>
                                <select
                                    className="form-select mb-3"
                                    name="post_type"
                                    {...register("post_type", {
                                        required: "Post Type is required",
                                    })}
                                    defaultValue="post" // Set default selected value
                                    onChange={handlePostTypeChange} // Handle change
                                >
                                    <option value="" hidden>Select</option>
                                    <option value="post">Post</option>
                                    <option value="reel">Reel</option>
                                    <option value="story">Story</option>
                                </select>
                                {errors.post_type && (
                                    <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                                        {errors.post_type.message}
                                    </div>
                                )}
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label">Add Labels</label>
                                    <select className="form-select mb-3"
                                        name="labels"
                                        {...register("labels", {
                                            required: "Labels is required",
                                        })}
                                    >
                                        <option value="" hidden>Select Label</option>
                                        <option value="1">Advertising</option>
                                    </select>
                                    {errors.labels && (
                                        <div
                                            style={{
                                                color: "red",
                                                fontSize: "14px",
                                                marginTop: "5px",
                                            }}
                                        >
                                            {errors.labels.message}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {alertMessage && (
                                <div className="col-md-4">
                                    <div className={`alert alert-${alertMessage.type}`} role="alert">
                                        <div className="d-flex align-items-start">
                                            <div className="me-2">
                                                {alertMessage.type === 'warning' && (
                                                    <i className='fas fa-exclamation-circle' style={{ fontSize: "24px", color: "#000" }}></i>
                                                )}
                                                {alertMessage.type === 'primary' && (
                                                    <i className="fas fa-info-circle" style={{ fontSize: "24px", color: "#000" }}></i>
                                                )}
                                            </div>
                                            <div>
                                                <div style={{ color: "black", fontSize: "14px" }}>
                                                    {alertMessage.message}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-8">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div className="user-img">
                                            <img src="/assets/images/icon-7797704_1280.png" alt="userimg" className="rounded-circle img-fluid" loading="lazy" width="40" />
                                        </div>
                                        <p className="px-2 mt-3 fw-bold">Vitel gloabal</p>
                                    </div>
                                    <div className="post-text ms-5 mb-4 position-relative">
                                        {selectedPostType !== 'story' && (
                                            <textarea
                                                className="form-control"
                                                id="exampleFormControlTextarea1"
                                                rows={7}
                                                data-gramm="false"
                                                name="caption"
                                                ref={textareaRef}
                                                {...register("caption", {
                                                    required: "Caption is required",
                                                })}
                                                maxLength="2200"
                                                value={captionText}
                                                onChange={(e) => {
                                                    // Handle caption change and enforce character limit
                                                    if (e.target.value.length <= 2200) {
                                                        setCaptionText(e.target.value);
                                                        setValue("caption", e.target.value, { shouldValidate: true }); // Ensure validation updates
                                                    }
                                                }}
                                            ></textarea>
                                        )}

                                        {/* Character count positioned on the right side */}
                                        {selectedPostType !== 'story' && (
                                            <span style={{ paddingLeft: '85%' }}>{captionText ? captionText?.length : 0} / 2200</span>
                                        )}

                                        {errors.caption && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.caption.message}
                                            </div>
                                        )}
                                    </div>

                                    <div>
                                        <div className="d-flex align-items-center ms-5">
                                            {/* Image Upload Button */}
                                            {(selectedPostType === 'post' || selectedPostType === 'story') && (
                                                <>
                                                    <a href="/#" className="me-2" onClick={handleIconClick}>
                                                        <button className="btn btn-soft-primary">
                                                            <i className="fa fa-picture-o fs-5" aria-hidden="true"></i>
                                                        </button>
                                                    </a>
                                                    {/* Hidden file input for images */}
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleFileChange(e, 'image')}
                                                    />
                                                </>
                                            )}

                                            {/* Video Upload Button */}
                                            {selectedPostType === 'reel' && (
                                                <>
                                                    <a href="/#" className="me-2" onClick={handleVideoClick}>
                                                        <button className="btn btn-soft-primary">
                                                            <i className="fa fa-file-video-o fs-5" aria-hidden="true"></i>
                                                        </button>
                                                    </a>
                                                    {/* Hidden file input for videos */}
                                                    <input
                                                        type="file"
                                                        ref={fileVideoRef}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => handleFileChange(e, 'video')}
                                                    />
                                                </>
                                            )}
                                            {/* Common gallary icon */}
                                            {/* <a href="/#" className="me-2" onClick={handleMediaIconClick}>
                                            <button className="btn btn-soft-primary">
                                                <i className="fa fa-folder-open fs-5" aria-hidden="true"></i>
                                            </button>
                                        </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card h-100 border-dashed">
                                    <div className="card-header">
                                        <div className="header-title">
                                            <h5 className="card-title">Preview</h5>
                                        </div>
                                    </div>
                                    <div className="card-body h-100 w-100">
                                        {(mediaFromCompose || imgUpload || videoUpload || storyUpload) &&
                                            <SocialContent
                                                upload={
                                                    mediaFromCompose ? mediaFromCompose :
                                                        watch("post_type") === "post" ? imgUpload :
                                                            watch("post_type") === "reel" ? videoUpload :
                                                                storyUpload
                                                }
                                                userData={userData}
                                                watch={watch('caption')}
                                                truncateName={truncateName}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="row align-items-center w-100">
                            <div className="form-group col-md-3">
                                <label className="form-label">Where to Post</label>
                                <select className="form-select"
                                    name="where_to_post"
                                    {...register("where_to_post", {
                                        required: "Where to Post is required",
                                    })}>
                                    <option value="" hidden>Select</option>
                                    <option value="instagram" selected>Instagram</option>
                                </select>
                                {errors.where_to_post && (
                                    <div
                                        style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {errors.where_to_post.message}
                                    </div>
                                )}
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Choose a Posting Action</label>
                                <select className="form-select"
                                    name="action"
                                    {...register("action", {
                                        // required: "Where to Post is required",
                                    })}
                                >
                                    <option value="" hidden>Select</option>
                                    {/* <option value="2">Save in Calendar</option> */}
                                    <option value="1" selected>Publish</option>
                                    <option value="2">Save to Draft (Feed)</option>
                                    <option value="3">Schedule</option>
                                    <option value="4">Send for approval</option>
                                </select>
                                {errors.action && (
                                    <div
                                        style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {errors.action.message}
                                    </div>
                                )}
                            </div>
                            {watch("action") === "3" && (
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className="form-label">When to Post</label>
                                        <Controller
                                            name="when_to_post" // Ensure this name matches your form structure
                                            control={control} // Pass control here
                                            render={({ field }) => (
                                                <>
                                                    <DatePicker
                                                        selected={field.value ? new Date(field.value) : new Date()} // Default to today's date
                                                        onChange={(date) => handleDateChange(date, field)}
                                                        showTimeSelect
                                                        timeFormat="hh:mm aa" // AM/PM format
                                                        timeIntervals={15}
                                                        dateFormat="MM/dd/yyyy hh:mm aa" // Include AM/PM in date format
                                                        placeholderText="MM/DD/YYYY HH:MM"
                                                        className={`px-3 form-control ${watch('when_to_post') && field.error ? 'is-invalid' : ''}`}
                                                        minDate={new Date()} // Ensure the minimum date is today
                                                        // Ensuring the today date is selected when opened
                                                        todayButton="Today" // Optional: Button to quickly select today's date
                                                    />
                                                    {field.error && <div className="invalid-feedback">{field.error.message}</div>}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                            {watch("action") === "4" && (
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className="form-label">Sub User</label>
                                        <select
                                            className="form-select"
                                            name="sub_user" // Ensure no extra spaces in the name attribute
                                            {...register("sub_user", {
                                                required: "Sub user is required when sending for approval",
                                            })}
                                        >
                                            <option value="" hidden>Select Sub User</option>
                                            {subUser.map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.user_email}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.sub_user && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.sub_user.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="col-md-3">
                                <button type="submit" className="btn btn-primary mt-3 px-3">Save</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal >
            <ShowComposeModal
                show={showMediaModal}
                onHide={handleMediaModalClose}
                onMediaSelect={handleAddToCompose}
            />
        </>
    )
}

