import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { YouTubePlayListCreate } from "../../utils/ApiClient";

const CreatePlayList = ({ buttonText, onPlaylistCreated, fetchPlaylistVideos }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const handleClose = () => {
    setShow(false);
    reset();
    setErrorMessage("");
  };

  const handleShow = () => setShow(true);

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorMessage("");

    try {
      const apiInput = {
        title: data.title,
        description: data.description,
      };
      const response = await YouTubePlayListCreate(apiInput);
      if (response?.status === 200 || response?.data?.success) {
        fetchPlaylistVideos();
        handleClose();
      } else {
        setErrorMessage("Failed to create playlist.");
      }
    } catch (err) {
      setErrorMessage("Unable to create playlist.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {buttonText || "Create PlayList"}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Playlist</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <div className="alert alert-danger">
                {errorMessage}
              </div>
            )}
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                {...register("title", { required: "Title is required" })}
                maxLength={150}
              />
              <Form.Text className="text-muted">
                {watch("title")?.length || 0}/150
              </Form.Text>
              {errors.title && (
                <div className="text-danger">
                  {errors.title.message}
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                {...register("description", { required: "Description is required" })}
                maxLength={5000}
              />
              <Form.Text className="text-muted">
                {watch("description")?.length || 0}/5000
              </Form.Text>
              {errors.description && (
                <div className="text-danger">
                  {errors.description.message}
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              className="w-100"
            >
              {loading ? "Creating..." : "Create"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CreatePlayList;
