// SocketContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import { getUserId, getLoginData } from './utils/CommonFunctions'

const SOCKET_SERVER_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your server URL
const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [userId, setUserId] = useState(getUserId() || null);

    useEffect(() => {
        // console.log("first", userId)
        if (userId) {
            let user_logged_data = getLoginData();
            var auth_user = user_logged_data.auth_user;
            var username = user_logged_data.username;
            var user_id = user_logged_data.user_id;

            // let auth_user = "amritha"
            // let username = "amritha"
            // let user_id = 1
            const newSocket = io(SOCKET_SERVER_URL, {
                auth: { auth_user, username, user_id },
                reconnection: true,
                reconnectionAttempts: 5,
                timeout: 10000,
            });
            setSocket(newSocket);

            // return () => {
            //     newSocket.disconnect();
            // };
        }
    }, [userId]);
    useEffect(() => {
        if (socket) {
            // Log the socket ID when the socket instance is set
            // console.log("Socket ID:", socket);

            // You might also want to handle other socket events or configurations here
        }
    }, [socket]);


    const initializeSocket = (newUserId) => {
        setUserId(newUserId);
    };

    return (
        <SocketContext.Provider value={{ socket, initializeSocket }}>
            {children}
        </SocketContext.Provider>
    );
};
