import React from 'react'

export default function Footer() {
    return (
        <footer class="iq-footer bg-white">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 d-flex justify-content-center align-items-center">
                        <p class="fw-500"> © Copyright 2024 <a href="#">Vitel global pvt ltd</a>  All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
