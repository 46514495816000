import React, { useState } from "react";
import { socialMediaMap } from "../../utils/Constants";

const SocialContent = ({
    upload,
    userData,
    watch,
    truncateName,
    selectedPlatform,
}) => {

    console.log("propsSocialContent", upload, userData, watch, truncateName, selectedPlatform)
    // const isFromCommonGallery = upload?.from || null;
    const isImage = upload?.file_type && ["jpg", "jpeg", "png", "gif", "bmp"].includes(
        upload.file_type.toLowerCase()
    );
    const isVideo =
        upload?.file_type &&
        ["mp4", "webm", "ogg"].includes(upload.file_type.toLowerCase());
    const contentType = upload?.content_type;
    const socialMedia = selectedPlatform; // Use selectedPlatform if where_to_post is not available

    const [isExpanded, setIsExpanded] = useState(false);
    console.log("contentType", contentType);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const formatDate = (date) => {
        const options = {
            day: "numeric",
            month: "long",
            hour: "numeric",
            minute: "numeric",
        };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);


    const contentRenderMap = {

        instagram: {
            story: () =>
                <section>
                    <div className="story__container">
                        <div className="d-flex justify-content-center align-items-center my-auto">
                            <section className="post">
                                <img
                                    src={upload?.preview}
                                    alt="Post"
                                    className="story__image"
                                />
                            </section>
                            <div className="story__content">
                                <div
                                    className="reel__reply w-100"
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <input
                                        type="text"
                                        className="form-control reply-input"
                                        placeholder="Write a reply..."
                                        disabled
                                        style={{ flex: 1, backgroundColor: "#e9ecef47" }}
                                    />
                                    <button
                                        disabled
                                        className="reply-send-button"
                                        style={{
                                            border: "none",
                                            background: "transparent",
                                            color: "white",
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon icon-tabler icon-tabler-send"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="10" y1="14" x2="21" y2="3" />
                                            <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>,
            post: (isImage) =>
                isImage && <div className="feed">
                    <section className="username">
                        <div className="image">
                            <a href={`https://www.instagram.com/${userData.username}`}>
                                <img src={userData.profile_picture_url} alt="userimg" />
                            </a>
                        </div>
                        <div className="id">
                            <a href={`https://www.instagram.com/${userData.username}`}>
                                {userData.username}
                            </a>
                        </div>
                    </section>
                    <section className="post">
                        <img src={upload?.preview} alt="post-img" />
                    </section>
                    <section className="btn-group">
                        <button type="button" className="btn btn-love">
                            <span className="material-symbols-outlined">favorite</span>
                        </button>

                        <button type="button" className="btn btn-comment">
                            <span className="material-symbols-outlined">maps_ugc</span>
                        </button>

                        <button type="button" className="btn btn-share">
                            <span className="material-symbols-outlined">share</span>
                        </button>

                        <button type="button" className="btn btn-bookmark">
                            <span className="material-symbols-outlined">bookmark</span>
                        </button>
                    </section>

                    <section className="caption">
                        <p>
                            <b>
                                <a
                                    className="id"
                                    href={`https://www.instagram.com/${userData.username}`}
                                >
                                    {userData.username}
                                </a>
                            </b>{" "}
                            {watch ? (
                                <>
                                    {isExpanded ? watch : `${watch?.substring(0, 100)}`}
                                    {watch?.length > 100 && (
                                        <button type="button" onClick={toggleExpand} className="btn btn-link py-0">
                                            {isExpanded ? "View Less" : "View More"}
                                        </button>
                                    )}
                                </>
                            ) : (
                                "Check out the caption here..."
                            )}
                        </p>
                    </section>
                </div>,
            reel: (isVideo) =>
                isVideo ? <section>
                    <div className="reel__container">
                        <video className="reel__video" autoPlay loop muted playsInline>
                            <source
                                src={upload.preview}
                                type={`video/${upload.file_type}`}
                            />
                            Your browser does not support the video tag.
                        </video>

                        <div className="reel__title">
                            <div className="reel__back-button">
                                {/* Back button content can be added here */}
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-camera"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"></path>
                                <circle cx="12" cy="13" r="3"></circle>
                            </svg>
                        </div>
                        <div className="reel__content">
                            <div className="reel__desc">
                                <div className="reel__user">
                                    <img
                                        src={userData.profile_picture_url}
                                        className="reel__avatar"
                                        alt="User Avatar"
                                    />
                                    <p className="reel__username mb-0">{userData.username}</p>
                                    <button className="btn btn-outline-light" disabled>
                                        Follow
                                    </button>
                                </div>
                                <p className="reel__caption">
                                    {watch ? truncateName(watch, 25) :
                                        "Check out the caption here..."
                                    }
                                </p>
                            </div>
                            <div className="reel__options">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-heart"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                    </svg>
                                    {/* <p className="reel__likes m-0">1,307</p> */}
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-message-circle"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-send"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <line x1="10" y1="14" x2="21" y2="3"></line>
                                    <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-dots-vertical"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                </svg>
                            </div>
                        </div>
                    </div>
                </section> : null,
        },
        facebook: {
            post: (isImage) =>
                isImage && <div id="facebook_text">
                    <div className="header">
                        <div className="left-info">
                            <div className="thumbnail">
                                <img src={userData.profile_picture_url} alt="user-img" />
                            </div>
                            <div className="name-info">
                                <div className="name">
                                    <a href={`https://www.facebook.com/${userData.username}`}>
                                        {userData.username}
                                    </a>
                                </div>
                                <div className="time">{formattedDate}</div>
                            </div>
                        </div>
                        <div className="right-info"></div>
                    </div>
                    <div className="content">
                        {isExpanded ? watch : `${watch?.substring(0, 60)}`}
                        {watch?.length > 60 && (
                            <button type='button' onClick={toggleExpand} className="btn btn-link py-0">
                                {isExpanded ? "View Less" : "View More"}
                            </button>
                        )}
                    </div>
                    {upload?.preview && (
                        <div className="facebook-post-image">
                            <img
                                src={upload?.preview}
                                alt="post-img"
                                className="img-fluid"
                            />
                        </div>
                    )}
                    <div className="feedback-action mt-2 ms-2">
                        <div className="fb-wrapper">
                            <i className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                                thumb_up
                            </i>Like
                        </div>
                        <div className="fb-wrapper">
                            <i className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                                maps_ugc
                            </i>
                            Comment
                        </div>

                        <div className="fb-wrapper">
                            <i className="fb-icon share"></i>Share
                        </div>
                    </div>
                </div>,
            text: () => <div id="facebook_text">
                <div class="header">
                    <div class="left-info">
                        <div class="thumbnail">
                            <img src={userData.profile_picture_url} alt="userimg" />
                        </div>
                        <div class="name-info">
                            <div class="name">
                                <a href={`https://www.instagram.com/${userData.username}`}>
                                    {userData.username}
                                </a>
                            </div>

                            <div class="time">{formattedDate}</div>
                        </div>
                    </div>
                    <div class="right-info"></div>
                </div>

                <div class="content">{isExpanded ? watch : `${watch?.substring(0, 100)}`}
                    {watch?.length > 100 && (
                        <button type='button' onClick={toggleExpand} className="btn btn-link py-0">
                            {isExpanded ? "View Less" : "View More"}
                        </button>
                    )}</div>

                <div class="feedback-action">
                    <div class="fb-wrapper">
                        <i class="fb-icon thumb-up far fa-thumbs-up"></i>Like
                    </div>
                    <div class="fb-wrapper">
                        <i class="fb-icon response far fa-comment-alt"></i>Comment
                    </div>
                    <div class="fb-wrapper">
                        <i class="fb-icon share"></i>Share
                    </div>
                </div>
            </div>,
            video: (isVideo) =>
                isVideo && <section>
                    <div className="reel__container">
                        <video className="reel__video" autoPlay loop muted playsInline>
                            <source
                                src={upload.preview}
                                type={`video/${upload.file_type}`}
                            />
                            Your browser does not support the video tag.
                        </video>

                        <div className="reel__title">
                            <div className="reel__back-button">
                                {/* Back button content can be added here */}
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-camera"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"></path>
                                <circle cx="12" cy="13" r="3"></circle>
                            </svg>
                        </div>
                        <div className="reel__content">
                            <div className="reel__desc">
                                <div className="reel__user">
                                    <img
                                        src={userData.profile_picture_url}
                                        className="reel__avatar"
                                        alt="User Avatar"
                                    />
                                    <p className="reel__username mb-0">{userData.username}</p>
                                    <button className="btn btn-outline-light" disabled>
                                        Follow
                                    </button>
                                </div>
                                <p className="reel__caption">
                                    {watch ? truncateName(watch, 25) :
                                        "Check out the caption here..."
                                    }
                                </p>
                            </div>
                            <div className="reel__options">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-hand-thumbs-up"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                                    </svg>
                                    {/* <p className="reel__likes m-0">1,307</p> */}
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-message-circle"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="white"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <circle cx="5" cy="12" r="2" fill="white"></circle>
                                    <circle cx="19" cy="5" r="2" fill="white"></circle>
                                    <circle cx="19" cy="19" r="2" fill="white"></circle>
                                    <path
                                        d="M7 12l10-7m0 14L7 12"
                                        stroke="white"
                                        stroke-width="2"
                                    ></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-dots-vertical"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>,
        },
        youtube: {
            video: (isVideo) =>
                isVideo &&
                <section>
                    <div className="reel__container">
                        <video className="reel__video" autoPlay loop muted playsInline>
                            <source
                                src={upload.preview}
                                type={`video/${upload.file_type}`}
                            />
                            Your browser does not support the video tag.
                        </video>

                        <div className="reel__title">
                            <div className="reel__back-button">
                                {/* Back button content can be added here */}
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-camera"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"></path>
                                <circle cx="12" cy="13" r="3"></circle>
                            </svg>
                        </div>
                        <div className="reel__content">
                            <div className="reel__desc">
                                <div className="reel__user">
                                    <img
                                        src={userData.profile_picture_url}
                                        className="reel__avatar"
                                        alt="User Avatar"
                                    />
                                    <p className="reel__username mb-0">{userData.username}</p>
                                    <button className="btn btn-outline-light" disabled>
                                        Follow
                                    </button>
                                </div>
                                <p className="reel__caption">
                                    {watch ? truncateName(watch, 25) : 'Check out the caption here'}
                                </p>
                            </div>
                            <div className="reel__options">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        fill="currentColor"
                                        className="bi bi-hand-thumbs-up"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                                    </svg>
                                    {/* <p className="reel__likes m-0">1,307</p> */}
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    class="bi bi-hand-thumbs-down"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856s-.036.586-.113.856c-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a10 10 0 0 1-.443-.05 9.36 9.36 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a9 9 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581s-.027-.414-.075-.581c-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.2 2.2 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.9.9 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1" />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-message-circle"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="white"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <circle cx="5" cy="12" r="2" fill="white"></circle>
                                    <circle cx="19" cy="5" r="2" fill="white"></circle>
                                    <circle cx="19" cy="19" r="2" fill="white"></circle>
                                    <path
                                        d="M7 12l10-7m0 14L7 12"
                                        stroke="white"
                                        stroke-width="2"
                                    ></path>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-dots-vertical"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>,
            // video: (isVideo) =>
            //     isVideo && <div id="youtube_post">
            //         <div className="rounded-lg overflow-hidden">
            //             {/* <img
            //                 src={video.thumbnailUrl}
            //                 alt={video.title}
            //                 className="w-full aspect-video object-cover"
            //             /> */}
            //             <video src={upload?.preview} controls className="img-fluid" />
            //             <div className="p-3">
            //                 <h3 className="font-medium line-clamp-2"> {watch ? (
            //                     truncateName(watch, 25)
            //                 ) : (
            //                     "Check out the caption here..."
            //                 )}</h3>
            //                 <p className="text-sm text-gray-600 mt-1">
            //                     Nov 12,2024
            //                 </p>
            //             </div>
            //         </div>
            //     </div>,
            // video: (isVideo) =>
            //     isVideo && <div id="youtube_post">
            //         <div className="header">
            //             <div className="left-info">
            //                 <div className="thumbnail">
            //                     <img src={userData.profile_picture_url} alt="user-img" />
            //                 </div>
            //                 <div className="name-info">
            //                     <div className="name">
            //                         <a href={`https://www.youtube.com/${userData.username}`}>
            //                             {userData.username}
            //                         </a>
            //                     </div>
            //                     <div className="time">{formattedDate}</div>
            //                 </div>
            //             </div>
            //             <div className="right-info"></div>
            //         </div>
            //         <div className="content">
            //             {watch ? (
            //                 truncateName(watch, 25)
            //             ) : (
            //                 "Check out the caption here..."
            //             )}
            //         </div>
            //         {upload?.preview && (
            //             <div className="youtube-post-video">
            //                 <video src={upload?.preview} controls className="img-fluid" />
            //             </div>
            //         )}
            //         <div className="feedback-action mt-2 ms-2">
            //             <div className="fb-wrapper">
            //                 <i className="material-symbols-outlined" style={{ marginRight: '5px' }}>
            //                     thumb_up
            //                 </i>Like
            //             </div>
            //             <div className="fb-wrapper">
            //                 <i className="material-symbols-outlined" style={{ marginRight: '5px' }}>
            //                     maps_ugc
            //                 </i>Comment
            //             </div>
            //             <div className="fb-wrapper">
            //                 <i className="fb-icon share"></i>Share
            //             </div>
            //         </div>
            //     </div>,
        },
        // Add more social media mappings if needed
    };


    const renderContent = () => {
        const platform = socialMediaMap[socialMedia];
        console.log("Resolved platform:", platform);

        if (!platform) {
            console.warn("Platform not found for:", socialMedia);
            return <p>Unsupported platform</p>;
        }

        const platformContent = contentRenderMap[platform];
        if (!platformContent) {
            console.warn("Content not found for platform:", platform);
            return <p>Content not found</p>;
        }

        const renderFn = platformContent[contentType];
        if (!renderFn) {
            console.warn(
                `Render function not found for content type: ${contentType} on platform: ${platform}`
            );
            return <p>Unsupported content type</p>;
        }

        // Call the render function dynamically
        return typeof renderFn === "function"
            ? renderFn(contentType === "post" ? isImage : isVideo)
            : renderFn;
    };

    return <div>{renderContent()}</div>;

};

export default SocialContent;
