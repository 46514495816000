import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getToken, logout, triggerAlert } from '../../utils/CommonFunctions';
import { fetchUserDetailsData } from '../../utils/ApiClient';
export default function HeaderSidebar() {
    const [userData, setUserData] = useState([]);
    const handleAddShow = () => setAddShow(true);
    const [addShow, setAddShow] = useState(false);
    const navigate = useNavigate();

    const fetchUserDetails = async () => {
        try {

            const response = await fetchUserDetailsData();
            const response_data = response.data;
            // console.log("data", response_data.error_code)
            if (response_data.error_code == 200) {
                const data = response.data.results;
                setUserData(data);


            } else {
                setUserData([])
            }
        } catch (error) {
            const response_data = error?.response?.data
        }
    }

    useEffect(() => {
        fetchUserDetails();
    }, []);

    const logoutUser = () => {
        try {

            if (logout()) {
                navigate('/login');
            } else {
                triggerAlert('error', 'Oops...', 'Something went wrong..');
            }

        } catch (error) {
            triggerAlert('error', 'Oops...', 'Something went wrong..');
        }

    };

    return (
        <div>
            <li class="nav-item dropdown user-dropdown">
                <a href="javascript:void(0);" class="d-flex align-items-center dropdown-toggle" id="drop-down-arrow"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="/assets/images/icon-7797704_1280.png" class=" rounded-circle me-3" alt="user"
                        loading="lazy" />
                </a>
                <div class="sub-drop dropdown-menu caption-menu" aria-labelledby="drop-down-arrow">
                    <div class="card shadow-none m-0">
                        <div class="card-header ">
                            <div class="header-title">
                                <h5 class="mb-0 ">Hello {userData.first_name ? userData.first_name : 0} {userData.last_name ? userData.last_name : 0}</h5>
                            </div>
                        </div>
                        <div class="card-body p-0 ">
                            <div class="d-flex align-items-center iq-sub-card border-0">
                                <span class="material-symbols-outlined">
                                    lock
                                </span>
                                <div class="ms-2">

                                    <div class="mb-0 h6" onClick={handleAddShow}>
                                        Change Password
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex align-items-center iq-sub-card border-0">
                                <span class="material-symbols-outlined">
                                    workspaces
                                </span>
                                <div class="ms-2">
                                    <Link to="/workspace" class="mb-0 h6">
                                        Workspace
                                    </Link>
                                </div>
                            </div>
                            <div class="d-flex align-items-center iq-sub-card border-0">
                                <span class="material-symbols-outlined">
                                    account_balance_wallet
                                </span>
                                <div class="ms-2">
                                    <Link to="/payment" class="mb-0 h6">
                                        Payment
                                    </Link>
                                </div>
                            </div>
                            <div class="d-flex align-items-center iq-sub-card border-0">
                                <span class="material-symbols-outlined">
                                    contact_support
                                </span>
                                <div class="ms-2">
                                    <Link to="/support-ticket" class="mb-0 h6">
                                        Support
                                    </Link>
                                </div>
                            </div>
                            <div class="d-flex align-items-center iq-sub-card" onClick={logoutUser}>
                                <span class="material-symbols-outlined">
                                    login
                                </span>
                                <div class="ms-2">
                                    <div class="mb-0 h6" >
                                        Sign out
                                    </div>
                                </div>
                            </div>
                            <div class=" iq-sub-card">
                                <h6>Company Accounts</h6>
                            </div>
                            <div class="d-flex align-items-center iq-sub-card border-0">
                                <span class="material-symbols-outlined">
                                    manage_accounts
                                </span>
                                <div class="ms-2">
                                    <Link to="/manage_users" class="mb-0 h6" >
                                        Add/Manage
                                    </Link>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </li>
        </div>
    )
}
