import { Navigate, Outlet } from 'react-router-dom';
import { isLogin } from '../utils/CommonFunctions';
import Layout from './Layout';
import { SocketProvider } from '../SocketContext';
import LayoutWorkspace from './LayoutWorkspace';

const PrivateRoutes = ({ withLayout = false }) => {
    const auth = isLogin();
    return (
        auth ? (
            <SocketProvider>
                {withLayout ? (
                    <Layout>
                        <Outlet />
                    </Layout>
                ) : (
                    <LayoutWorkspace >

                        <Outlet />
                    </LayoutWorkspace>
                )}
            </SocketProvider>
        ) : (
            <Navigate to='/login' />
        )
    );
};

export default PrivateRoutes;
