import React, { useState, useRef, useEffect, useCallback } from "react";
import { Offcanvas, Button, Form } from "react-bootstrap";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { getBase64, triggerAlert } from "../../utils/CommonFunctions";
import { mediaGallery, getMediaGallery } from "../../utils/ApiClient";
import ImageLazyLoading from "../../common/components/ImageLazyLoading";

const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

const ShowComposeModal = ({ show, onHide, onMediaSelect }) => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [isBlue, setIsBlue] = useState(true);
  const [mediaUpload, setMediaUpload] = useState(null);
  const [mediaData, setMediaData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("image");
  const [searchQuery, setSearchQuery] = useState("");
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState(null);
  const fileInputRef = useRef(null);
  const { register, handleSubmit, reset } = useForm();
  const resizeObserverRef = useRef(null);

  const handleComposeClose = () => {
    onHide();
    reset();
    setMediaUpload(null);
    setModalOpened(false);
    setSelectedMedia(null);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (
      file.size > 50 * 1024 * 1024 ||
      !["image/jpeg", "image/png", "image/jpg", "video/mp4", "video/webm", "video/ogg"].includes(file.type)
    ) {
      e.target.value = "";
      triggerAlert(
        "error",
        "Invalid File",
        "Please upload a valid image or video under 50MB."
      );
      setMediaUpload(null);
      return;
    }

    try {
      const base64 = await getBase64(file);
      const base64WithoutPrefix = base64.substring(base64.indexOf(",") + 1);
      const items = {
        file_name: file.name,
        file_type: file.type,
        file_size: file.size,
        file: base64WithoutPrefix,
        preview: base64,
      };

      setMediaUpload(items);
    } catch (error) {
      triggerAlert("error", "Oops...", "Failed to process the file.");
    }
  };

  const commonCompose = async (data) => {
    const params = {
      access_token:
        "EAAFZBkHk1F7cBOxNdLxwae8IdFRBso26psWeCxdNRqY2tz2H5q8hQj7RLWUEt2dq3vwveF0ZAAx9QEQyZBa29vKK5kuJF4gHe4CKv2ZA0ZAW7v2gorLcy8UTSJMm2ebtBZAxAfhN6KjxE1FREwFrsN6tQLsVrjoDXWhJlrFGd5jYvZAj54SRdXL1rpwQTm5qgWgSW2x7xKL",
      business_id: "17841464028464664",
    };

    try {
      const apiInput = {
        title: data.title,
        file_upload: mediaUpload ? mediaUpload.file : null,
      };

      if (!apiInput.file_upload) {
        triggerAlert("error", "No File", "Please upload an image or video.");
        return;
      }

      const formData = new FormData();
      formData.append("title", apiInput.title);
      formData.append("file_upload", apiInput.file_upload);

      const response = await mediaGallery(formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(`Upload Progress: ${percentCompleted}%`);
        },
      });

      if (response.data.error_code === 201) {
        triggerAlert("success", "Success", "File uploaded successfully!");
        fetchMediaGallery({ file_upload: selectedFilter, title: searchQuery });
        handleComposeClose();
      } else {
        triggerAlert("error", "Error", "Failed to upload file.");
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong.");
    }
  };

  const fetchMediaGallery = useCallback(async (params) => {
    try {
      const response = await getMediaGallery(params);
      if (response.data.error_code === 200) {
        setMediaData(response.data.results);
      } else {
        triggerAlert("error", "Error", "Failed to fetch media gallery.");
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong.");
    }
  }, []);

  useEffect(() => {
    if (show) {
      setModalOpened(true);
      fetchMediaGallery({ file_upload: selectedFilter, title: searchQuery });
    }
  }, [show, fetchMediaGallery, selectedFilter, searchQuery]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.scrollHeight - 2
      ) {
        loadMoreData();
      }
    }, 200);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page]);

  useEffect(() => {
    const handleResize = debounce(() => {
      // Handle resize events here
    }, 200);

    const element = document.querySelector(".offcanvas-body");
    if (element) {
      resizeObserverRef.current = new ResizeObserver(handleResize);
      resizeObserverRef.current.observe(element);
    }

    return () => {
      if (element && resizeObserverRef.current) {
        resizeObserverRef.current.unobserve(element);
      }
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  const loadMoreData = useCallback(() => {
    if (!hasMore) return;

    const newData = mediaData.slice(page * 9, (page + 1) * 9);

    setTimeout(() => {
      setMediaData([...mediaData, ...newData]);
      setPage(page + 1);
      if (newData.length < 9) {
        setHasMore(false);
      }
    }, 2000);
  }, [page, mediaData, hasMore]);

  const handleMediaSelect = (media) => {
    setSelectedMedia(media);
    if (onMediaSelect) {
      onMediaSelect(media);
      handleComposeClose();
    }
  };

  const handleVideoPlay = (event) => {
    const videoElement = event.target;
    if (currentPlayingVideo && currentPlayingVideo !== videoElement) {
      currentPlayingVideo.pause();
    }
    setCurrentPlayingVideo(videoElement);
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleComposeClose}
      placement="end"
      style={{ width: "600px" }}
    >
      <Offcanvas.Header
        closeButton
        style={{
          borderBottom: "1px solid #dee2e6",
          padding: "1rem",
        }}
      >
        <Offcanvas.Title
          style={{
            fontSize: "1.1rem",
            fontWeight: "500",
          }}
        >
          <Button onClick={() => fileInputRef.current.click()}>
            <FaCloudUploadAlt style={{ marginRight: "8px" }} /> Upload File
          </Button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body
        className="offcanvas-body"
        style={{
          backgroundColor: isBlue ? "white" : "orange",
          transition: "background-color 0.5s ease",
          padding: "1rem",
        }}
      >
        <Form.Group controlId="filter" style={{ marginBottom: "1rem" }}>
          <Form.Label style={{ fontWeight: "bold" }}>
            Select Gallery Type
          </Form.Label>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Form.Check
              type="radio"
              label="Image Gallery"
              name="filter"
              value="image"
              checked={selectedFilter === "image"}
              onChange={(e) => setSelectedFilter(e.target.value)}
              style={{ fontSize: "0.9rem" }}
            />
            <Form.Check
              type="radio"
              label="Video Gallery"
              name="filter"
              value="video"
              checked={selectedFilter === "video"}
              onChange={(e) => setSelectedFilter(e.target.value)}
              style={{ fontSize: "0.9rem" }}
            />
          </div>
        </Form.Group>

        <form onSubmit={handleSubmit(commonCompose)}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/jpeg,image/png,video/mp4,video/webm,video/ogg"
            style={{ display: "none" }}
          />
          {mediaUpload && (
            <>
              <input
                type="text"
                {...register("title", { required: true })}
                placeholder="Enter title"
                style={{ marginTop: "1rem", width: "100%", padding: "0.5rem" }}
              />
              {mediaUpload.file_type.startsWith("image") ? (
                <div style={{ marginTop: "1rem" }}>
                  <img
                    src={mediaUpload.preview}
                    alt="Preview"
                    style={{ width: "100%", borderRadius: "8px" }}
                  />
                  <p style={{ marginTop: "0.5rem", fontWeight: "bold" }}>
                    {mediaUpload.file_name}
                  </p>
                </div>
              ) : (
                <div style={{ marginTop: "1rem" }}>
                  <video
                    src={mediaUpload.preview}
                    controls
                    style={{ width: "100%", borderRadius: "8px" }}
                  />
                  <p style={{ marginTop: "0.5rem", fontWeight: "bold" }}>
                    {mediaUpload.file_name}
                  </p>
                </div>
              )}
              <button
                type="submit"
                className="btn-submit"
                style={{
                  marginTop: "1rem",
                  padding: "10px 20px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  border: "none",
                  backgroundColor: "#ff7f50",
                  color: "#fff",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "all 0.3s ease",
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = "#ff4500";
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 6px 12px rgba(0, 0, 0, 0.15)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = "#ff7f50";
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                Save
              </button>
            </>
          )}
        </form>

        <Form.Group controlId="search" style={{ marginTop: "2rem" }}>
          <Form.Label>Search</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search by title"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Form.Group>

        <div className="row g-3" style={{ marginTop: "2rem" }}>
          {mediaData.map((media, index) => (
            <div className="col-sm-4" key={index}>
              <div
                className="card mb-3 blur-effect"
                style={{
                  border: "1px solid #dee2e6",
                  borderRadius: "0.375rem",
                  overflow: "hidden",
                }}
              >
                {media.file_upload === "image" ? (
                  <ImageLazyLoading
                    src={`${BASE_URL}${media.doc_path}`}
                    alt={media.title}
                    effect="blur"
                    style={{
                      height: "150px",
                      objectFit: "cover",
                      width: "100%",
                    }}
                    wrapperClassName="image-wrapper"
                    onLoad={() => {
                      const cardElement = document.querySelector(
                        `.blur-effect:nth-child(${index + 1})`
                      );
                      if (cardElement) {
                        cardElement.classList.add("loaded");
                      }
                    }}
                  />
                ) : (
                  <video
                    src={`${BASE_URL}${media.doc_path}`}
                    controls
                    style={{
                      height: "150px",
                      objectFit: "cover",
                      width: "100%",
                    }}
                    className="lazyload"
                    data-src={`${BASE_URL}${media.doc_path}`}
                    onPlay={handleVideoPlay}
                  />
                )}

                <div
                  className="card-body p-2"
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  <h6
                    className="card-title mb-0"
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      color: "#333",
                    }}
                  >
                    {media.title}
                  </h6>
                  {modalOpened && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <input
                        type="radio"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "8px",
                          cursor: "pointer",
                        }}
                        checked={
                          selectedMedia &&
                          selectedMedia.type === media.file_upload &&
                          selectedMedia.data.doc_path === media.doc_path
                        }
                        onChange={() =>
                          handleMediaSelect({ type: media.file_upload, data: media })
                        }
                      />
                      <span>Select</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ShowComposeModal;
