import React from 'react';

const SocialContent = ({ upload, userData, watch, truncateName }) => {
    const base_url = process.env.REACT_APP_API_BASE_URL;
    // Check if content is from the common gallery
    const isFromCommonGallery = upload?.from || null;

    // Determine if the upload is an image or video
    const isImage = upload?.file_type && ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(upload.file_type.toLowerCase());
    const isVideo = upload?.file_type && ['mp4', 'webm', 'ogg'].includes(upload.file_type.toLowerCase());

    // Set content type based on the source
    let contentType;
    // if (!isFromCommonGallery) {
    contentType = upload?.content_type;
    // } else {
    //     contentType = upload?.type === "image" ? 'post' :
    //         upload?.type === "video" ? 'reel' : 'story';
    // }

    // Return null if there’s no preview
    if (!upload?.preview) return null;

    // Log the content data
    const renderContent = () => {
        if (contentType === 'story') {
            return (
                <section>
                    <div className="story__container">
                        <div className="d-flex justify-content-center align-items-center my-auto">
                            <section className="post">
                                <img src={
                                    // isFromCommonGallery ? base_url + upload.doc_path :
                                    upload?.preview} alt="Post" />
                            </section>
                            <div className="story__content">
                                <div className="reel__reply w-100" style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        className="form-control reply-input"
                                        placeholder="Write a reply..."
                                        disabled
                                        style={{ flex: 1, backgroundColor: "#e9ecef47" }}
                                    />
                                    <button
                                        disabled
                                        className="reply-send-button"
                                        style={{ border: 'none', background: 'transparent', color: 'white' }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="10" y1="14" x2="21" y2="3" />
                                            <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }

        if (contentType === 'reel' && isVideo) {
            return (
                <section>
                    <div className="reel__container">
                        <video
                            className="reel__video"
                            autoPlay
                            loop
                            muted
                            playsInline
                        >
                            <source src={
                                // isFromCommonGallery ? base_url + upload.doc_path :
                                upload.preview} type={`video/${upload.file_type}`} />
                            Your browser does not support the video tag.
                        </video>

                        <div className="reel__title">
                            <div className="reel__back-button">
                                {/* Back button content can be added here */}
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-camera" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"></path>
                                <circle cx="12" cy="13" r="3"></circle>
                            </svg>
                        </div>
                        <div className="reel__content ">
                            <div className="reel__desc">
                                <div className="reel__user">
                                    <img src={userData.profile_picture_url} className="reel__avatar" alt="User Avatar" />
                                    <p className="reel__username mb-0">{userData.username}</p>
                                    <button className='btn btn-outline-light' disabled>Follow</button>
                                </div>
                                <p className="reel__caption">
                                    {watch ? truncateName(watch) : "Check out the caption here..."}
                                </p>
                            </div>
                            <div className="reel__options">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                                    </svg>
                                    <p className="reel__likes m-0">1,307</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-circle" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <line x1="10" y1="14" x2="21" y2="3"></line>
                                    <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }

        if (contentType === "post" && isImage) {
            return (
                <div className="feed">
                    <section className="username">
                        <div className="image">
                            <a href={`https://www.instagram.com/${userData.username}`}>
                                <img src={userData.profile_picture_url} alt='userimg' />
                            </a>
                        </div>
                        <div className="id">
                            <a href={`https://www.instagram.com/${userData.username}`}>{userData.username}</a>
                        </div>
                    </section>
                    <section className="post">
                        <img src={
                            // isFromCommonGallery ? base_url + upload.doc_path :
                            upload?.preview} alt='post-img' />
                    </section>
                    <section className="btn-group">
                        <button type="button" className="btn-love"><i className="far fa-heart fa-lg"></i></button>
                        <button type="button" className="btn-comment"><i className="far fa-comment fa-lg"></i></button>
                        <button type="button" className="btn-share"><i className="fas fa-share fa-lg"></i></button>
                        <button type="button" className="btn-bookmark"><i className="far fa-bookmark fa-lg"></i></button>
                    </section>
                    <section className="caption">
                        <p>
                            <b>
                                <a className="id" href={`https://www.instagram.com/${userData.username}`}>
                                    {userData.username}
                                </a>
                            </b>
                            {' '}
                            {watch || "Check out the caption here..."}
                        </p>
                    </section>
                </div>
            );
        }

        return null;
    };

    return renderContent();
};

export default SocialContent;