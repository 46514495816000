import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { fetchCustomerMenu } from '../utils/ApiClient';
import { getCompanyName, truncateName } from '../utils/CommonFunctions';
import { useSharedState } from './components/context/SidebarContext';
import Skeleton from 'react-loading-skeleton';
// import "./CommunicationSidebar.css"


export default function CommunicationSidebar({ toggleSidebar }) {
  const location = useLocation();
  const { sidebarType } = useSharedState();
  // console.log("sharedState", sidebarType)
  const [menuData, setmenuData] = useState([]);
  const currentMenuType = sidebarType;
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMenuListApi = async () => {
    setIsLoading(true);
    try {
      const response = await fetchCustomerMenu(currentMenuType);
      const response_data = response;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results.data;
        const user_data = itemsArray?.user_data;
        const user_type = user_data.user_type;
        const sub_user_type = user_data.sub_user_type;
        const menu_data = itemsArray.menu_data;
        setIsLoading(false);
        setmenuData(menu_data);
      } else if (response.status === 204) {
        setIsLoading(false);
        // Handle no content
      } else {
        setIsLoading(false);
        // Handle error
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentMenuType !== undefined) {
      fetchMenuListApi();
    }
  }, [currentMenuType]);



  const pathSegments = location.pathname.split('/').filter(Boolean);
  const activeMenu = pathSegments[0];
  const activeSubMenu = pathSegments[1];
  const activeNestedItem = pathSegments[2];

  const company_name = getCompanyName();

  // Utility function to check if the full path matches
  const isActive = (menuPath, subMenuPath, nestedItemPath) => {
    return (
      (menuPath === activeMenu) &&
      (subMenuPath === activeSubMenu || !subMenuPath) &&
      (nestedItemPath === activeNestedItem || !nestedItemPath)
    );
  };

  // Update expanded menu when location changes
  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    if (pathSegments[0]) {
      setExpandedMenu(pathSegments[0]);
    }
  }, [location.pathname]);

  const handleMenuClick = (menuPageName, event) => {
    if (expandedMenu === menuPageName) {
      setExpandedMenu(null);
    } else {
      setExpandedMenu(menuPageName);
    }
  };

  return (
    <aside className={`sidebar sidebar-default sidebar-base navs-rounded-all sidebar-hover ${toggleSidebar ? 'sidebar-mini' : ""}`} id="first-tour" data-toggle="main-sidebar" data-sidebar="responsive">
      <div className="sidebar-body pt-0 data-scrollbar" data-scrollbar="true" tabindex="-1" style={{ overflow: 'hidden', outline: 'none' }}>
        <div className="scroll-content">
          <div className="sidebar-list">
            <ul className="navbar-nav iq-main-menu" id="sidebar-menu">
              {isLoading ?
                <li class="nav-item  mt-3 mb-0">
                  <Skeleton rectangle width={40} height={40} count={10} />
                </li> :
                <>
                  <li className="nav-item mt-3 mb-0">
                    <a className="nav-link static-item disabled" href="#" tabindex="-1">
                      <span className="default-icon fw-500 text-primay">{company_name ? truncateName(company_name, 25) : '-'}</span>
                      <span className="mini-icon" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Social">-</span>
                    </a>
                  </li>
                  {menuData && menuData.length > 0 && (
                    menuData.map((menuItem, index) => {
                      const isActiveMenu = menuItem.page_name === activeMenu;
                      const isMenuExpanded = menuItem.page_name === expandedMenu;

                      return (
                        <li className={`nav-item ${isActiveMenu ? 'active' : ''}`} key={index}>
                          <a
                            className={`nav-link ${isActiveMenu ? 'active' : ''}`}
                            data-bs-toggle="collapse"
                            href={`#menu-item-${index}`}
                            role="button"
                            aria-expanded={isMenuExpanded}
                            aria-controls={`menu-item-${index}`}
                            aria-current="page"
                            onClick={(e) => handleMenuClick(menuItem.page_name, e)}
                          >
                            <img
                              src={`/assets/images/icon/${menuItem.menu_image}`}
                              className="img-fluid"
                              alt={menuItem.menu_name}
                              loading="lazy"
                            />
                            <span className="item-name">{menuItem.menu_name}</span>
                            <i className="right-icon material-symbols-outlined">chevron_right</i>
                          </a>

                          {menuItem.submenu && menuItem.submenu.length > 0 && (
                            <ul className={`sub-nav collapse ${isMenuExpanded ? 'show' : ''}`} id={`menu-item-${index}`} data-bs-parent="#sidebar-menu">
                              {menuItem.submenu.map((subItem, subIndex) => {
                                const isActiveSubMenu = isActive(menuItem.page_name, subItem.page_name, null);

                                return (
                                  <li className="nav-item mt-2" key={subIndex}>
                                    <Link
                                      className={`nav-link ${isActiveSubMenu ? 'active' : ''}`}
                                      data-bs-toggle={subItem.submenu && subItem.submenu.length > 0 ? "collapse" : ""}
                                      to={subItem.submenu && subItem.submenu.length > 0 ? `#friend-list${index}-${subIndex}` : `/${menuItem.page_name}/${subItem.page_name}`}
                                      aria-expanded={isActiveSubMenu}
                                      aria-current="page"
                                    >
                                      {/\.(png|jpe?g|gif)$/i.test(subItem.menu_image) ? (
                                        <img
                                          src={`/assets/images/icon/${subItem.menu_image}`}
                                          className="img-fluid"
                                          alt={subItem.menu_name}
                                          loading="lazy"
                                          width="25"
                                        />
                                      ) : (
                                        <i className="icon material-symbols-outlined sidenav-mini-icon">{subItem.menu_image}</i>
                                      )}
                                      <span className="item-name">{subItem.menu_name}</span>
                                      {subItem.submenu && subItem.submenu.length > 0 && (
                                        <i className="right-icon material-symbols-outlined">chevron_right</i>
                                      )}
                                    </Link>

                                    {subItem.submenu && subItem.submenu.length > 0 && (
                                      <ul className={`sub-nav collapse ${isActiveSubMenu ? 'show' : ''}`} id={`friend-list${index}-${subIndex}`} data-bs-parent={`#menu-item-${index}`}>
                                        {subItem.submenu.map((nestedItem, nestedIndex) => {
                                          const isActiveNestedItem = isActive(menuItem.page_name, subItem.page_name, nestedItem.page_name);
                                          return (
                                            <li className="nav-item" key={nestedIndex}>
                                              <Link
                                                className={`nav-link ${isActiveNestedItem ? 'active' : ''}`}
                                                to={`/${menuItem.page_name}/${subItem.page_name}/${nestedItem.page_name}`}
                                              >
                                                <i className="icon material-symbols-outlined sidenav-mini-icon">{nestedItem.menu_image}</i>
                                                {nestedItem.menu_name ? truncateName(nestedItem.menu_name, 15) : '-'}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })
                  )}
                </>
              }

            </ul>
          </div>
        </div>
        <div className="scrollbar-track scrollbar-track-x" style={{ display: 'none' }}>
          <div className="scrollbar-thumb scrollbar-thumb-x" style={{ width: "250px", transform: "translate3d(0px, 0px, 0px)" }}></div>
        </div>
        <div className="scrollbar-track scrollbar-track-y" style={{ display: 'block' }}>
          <div className="scrollbar-thumb scrollbar-thumb-y" style={{ height: "71.025px", transform: "translate3d(0px, 0px, 0px)" }}></div>
        </div>
      </div>
    </aside>
  );
}
