import React, { useRef, useState, useEffect, useReducer } from 'react';
import { getBase64, pageReload, triggerAlert } from '../../utils/CommonFunctions';
import { useForm, Controller } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { commonComposeAPI, commonVideoAPI, SubUserList, commonStoryAPI, subUserData, commonTextAPI, facebookPostApi, facebookReelApi, commonYoutubeApi } from '../../utils/ApiClient';
import './Compose.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShowComposeModal from './ShowComposeModal';
import { truncateName } from '../../utils/CommonFunctions'
import SocialContent from './Preview';
import Loader from '../../common/components/Loader';
import MultiSelectStatic from '../../common/components/selects/MultiSelectStatic';
import { platformLimits, whereToPost } from '../../utils/Constants';
import Emojis from '../../common/components/Emojis';
import PlaylistModal from '../YouTube/Playlist';

const initialState = {
    selectedPlatform: [],
    activePlatform: null,
    selectedPostType: 'post',
    alertMessage: null,
    fileType: null,  // Store selected file type (image/video)
    uploads: {
        video: null,
        reel: null,
        story: null,
        post: null,
        reel: null,
    },
    showEmojisReaction: false
};

const platformReducer = (state, action) => {
    console.log("Reducer action:", action);
    switch (action.type) {
        case 'SET_SELECTED_PLATFORM':
            return {
                ...state,
                selectedPlatform: action.payload,
                activePlatform: action.payload.length > 0 ? action.payload[0]?.value : null, // Set active platform based on the selected one
                showEmojisReaction: false
            };
        case 'SET_SELECTED_POSTTYPE':
            return {
                ...state,
                selectedPostType: action.payload,
                uploads: {}, // Clear uploads when post type changes
                showEmojisReaction: false
            };
        case 'SET_ACTIVE_PLATFORM':
            return {
                ...state,
                activePlatform: action.payload,
            };
        case 'SET_ALERT_MESSAGE':
            return {
                ...state,
                alertMessage: action.payload,
            };
        case 'SET_FILE_TYPE':
            return {
                ...state,
                fileType: action.payload,
            };
        case 'SET_UPLOAD':
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    [action.payload.contentType]: action.payload.items,
                },
            };
        case "SHOW_EMOJIS":
            return {
                ...state,
                showEmojisReaction: action.payload,
            };
        case 'RESET_STATE':
            return initialState;
        default:
            return state;
    }
};

export default function Compose() {
    const [state, dispatch] = useReducer(platformReducer, initialState);
    const fileInputRef = useRef(null);
    const textareaRef = useRef(null); // Reference to the textarea
    const fileVideoRef = useRef(null);
    const [showComposeModal, setShowComposeModal] = useState(false);
    const [userData, setUserData] = useState([]);

    const [showMediaModal, setShowMediaModal] = useState(false); // New state for media modal
    const [isLoading, setIsLoading] = useState(false);
    const [subUser, setSubUser] = useState([]);

    const { register, handleSubmit, formState: { errors }, reset, setValue, setError, control, clearErrors, watch } = useForm({
        defaultValues: {
            post_type: 'post', // Set default value for post_type
            labels: '1', // Default value for labels is "1" (corresponding to "Advertising")
            // when_to_post: new Date().toISOString(),
            // where_to_post: 'instagram', // Set default value for where_to_post

        }
    });



    const [mediaFromCompose, setMediaFromCompose] = useState(null);

    const handleShowComposeModal = () => {
        setShowComposeModal(true);
    };


    const handleComposeClose = () => {
        setShowComposeModal(false); // Close the modal
        reset(); // Reset form values (this will reset all form inputs, including 'post_type')
        dispatch({ type: 'RESET_STATE' }); // Clear all state values
    };

    const handleDateChange = (date, field) => {
        const today = new Date();
        // Set time to 00:00:00 for today to ensure we only compare dates
        today.setHours(0, 0, 0, 0);

        // Clear errors initially
        clearErrors('when_to_post');

        // Check if the selected date is before today
        if (date) {
            const selectedDate = new Date(date);
            selectedDate.setHours(0, 0, 0, 0); // Set the selected date to 00:00:00

            if (selectedDate < today) {
                setError('when_to_post', {
                    type: 'manual',
                    message: 'Date and time must be today or later.',
                });
            } else {
                field.onChange(date); // Update the field if valid
            }
        } else {
            field.onChange(null); // Clear field if no date is selected
        }
    };


    const fetchSubUser = async () => {
        const user_id = '17841464028464664'; // Replace with the actual user ID
        const access_token = 'EAAFZBkHk1F7cBOxNdLxwae8IdFRBso26psWeCxdNRqY2tz2H5q8hQj7RLWUEt2dq3vwveF0ZAAx9QEQyZBa29vKK5kuJF4gHe4CKv2ZA0ZAW7v2gorLcy8UTSJMm2ebtBZAxAfhN6KjxE1FREwFrsN6tQLsVrjoDXWhJlrFGd5jYvZAj54SRdXL1rpwQTm5qgWgSW2x7xKL'; // Replace with the actual access token

        try {
            const params = {
                access_token: access_token,
            };

            // Pass user_id and params to the API function
            const response = await SubUserList(user_id, params);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const data = response_data.results; // Use response_data to access results
                setUserData(data);
            } else {
                setUserData([]);
            }
        } catch (error) {
            const response_data = error?.response?.data;
            console.error('Error fetching sub-user data:', response_data?.message || 'Something went wrong!');
        }
    };

    useEffect(() => {
        fetchSubUser(); // Initial fetch with user_id and access_token
    }, []);



    const handleVideoClick = () => {
        if (fileVideoRef.current) {
            fileVideoRef.current.click();
        }
    };

    const handleIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleMediaModalClose = () => {
        setShowMediaModal(false);
    };

    const handleAddToCompose = (mediaData) => {
        mediaData.from = "common_gallery";
        if (mediaData.type === 'image') {
            setMediaFromCompose(mediaData)
        } else if (mediaData.type === 'video') {
            setMediaFromCompose(mediaData)
        }
    };
    const fetchSubUserData = async () => {
        try {
            setIsLoading(true);
            const response = await subUserData(); // Your API call function

            if (response?.data?.results?.length) {
                setSubUser(response.data.results); // Update state with sub-user data
            }
        } catch (error) {
            console.log(error, "error");
            triggerAlert('error', '', error?.response?.data?.message || "Something went wrong!");
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch sub-user data when the component mounts
    useEffect(() => {
        fetchSubUserData();
    }, []);

    // const handlePlatformChange = (e) => {
    //     const value = e.target.value;
    //     setSelectedPlatform(value); // Update the selected platform
    //     reset({
    //         where_to_post: value, // Set the selected value as the new default
    //     });
    //     setImgUpload(null); // Clear uploaded image
    //     setVideoUpload(null); // Clear uploaded video
    //     setCaptionText(''); // Clear caption text
    //     setStoryUpload(null); // Clear story upload if needed
    //     setTextUpload(null);
    //     clearErrors(); // Clear validation errors
    //     setAlertMessage(""); // Clear any existing alert messages when platform changes

    //     // Reset post type based on the selected platform
    //     if (value === 'instagram' || value === 'facebook') {
    //         setSelectedPostType('post');
    //         setValue("post_type", "post");
    //     } else if (value === 'youtube') {
    //         setSelectedPostType('video');
    //         setValue("post_type", "video");
    //     }
    // };

    const handleMediaIconClick = () => {
        setShowMediaModal(true); // Open media modal when icon is clicked
    };

    const handleWhereToPost = (selected) => {
        dispatch({
            type: 'SET_SELECTED_PLATFORM',
            payload: selected,
        });
        setValue('where_to_post', selected);  // Keep this as it updates the form value
        setValue('caption', '')
    };
    const handlePostType = (e) => {
        dispatch({
            type: 'SET_SELECTED_POSTTYPE',
            payload: e.target.value,
        });
        setValue('post_type', e.target.value);  // Keep this as it updates the form value
    };

    const handleShowEmojis = (showEmojis) => {
        dispatch({
            type: 'SHOW_EMOJIS',
            payload: !showEmojis
        })
    }

    const handleReactionSelect = async (emoji) => {
        const existingCaption = watch('caption');
        setValue('caption', existingCaption + emoji)
    }


    const getAllowedPostTypes = () => {
        if (state.selectedPlatform.length === 0) return [];

        // Get common post types for all selected platforms
        const selectedPostTypes = state.selectedPlatform.map((platform) => {
            const match = whereToPost.find((item) => item.value === platform.value);
            return match ? match.postTypes : [];
        });

        // Calculate the intersection of post types
        return selectedPostTypes.reduce((a, b) => a.filter((type) => b.includes(type)));
    };

    const allowedPostTypes = getAllowedPostTypes();


    // Handler to toggle active platform
    const handleActivePlatform = (platformValue) => {
        dispatch({
            type: 'SET_ACTIVE_PLATFORM',
            payload: platformValue,
        });
    };

    const processFile = async (file, items, contentType) => {
        // alert('processing file')
        try {
            const base64 = await getBase64(file);  // Convert file to base64
            items = {
                file_name: file.name,
                file_type: file.name.split(".").pop(),
                file_size: file.size,
                file: base64.substring(base64.indexOf(",") + 1),
                preview: base64,
                content_type: contentType
            };

            // Dispatch action to update the appropriate upload in state
            dispatch({
                type: 'SET_UPLOAD',
                payload: { contentType, items }
            });

            // Clear alert message after successful file processing
            dispatch({ type: 'SET_ALERT_MESSAGE', payload: null });
        } catch (error) {
            console.error("Error converting file to base64:", error);
            dispatch({
                type: 'SET_ALERT_MESSAGE',
                payload: { type: "warning", message: "Failed to process the file." }
            });
        }
    };


    const handleFileChange = async (e, type) => {
        const file = e.target.files[0];
        if (!file) return;

        let items = {};
        // let contentType = type === "video" ? "reel" : state.selectedPostType; // Set content type based on type or postType
        let contentType = state.selectedPostType; // Set content type based on type or postType

        // Clear previous alert message
        dispatch({ type: 'SET_ALERT_MESSAGE', payload: null });
        e.target.value = '';

        // Size restrictions based on type
        const sizeLimit = type === "video" ? 650 * 1024 * 1024 : 30 * 1024 * 1024;
        if (file.size > sizeLimit) {
            dispatch({
                type: 'SET_ALERT_MESSAGE',
                payload: {
                    type: "warning",
                    message: `File size should not exceed ${type === "video" ? "650MB" : "30MB"}.`
                }
            });
            return;
        }

        // Handle PDF restriction
        // if (contentType === "post" && file.type === "application/pdf") {
        // dispatch({
        //     type: 'SET_ALERT_MESSAGE',
        //     payload: { type: "warning", message: "PDF files are not allowed." }
        // });
        // return;
        // }

        // Type-specific validations
        const isImage = file.type.startsWith("image/");
        const isVideo = file.type.startsWith("video/");
        const allowedVideoTypes = ["video/mp4", "video/gif", "video/mov"];

        if (type === "video" && isVideo && allowedVideoTypes.includes(file.type)) {
            const video = document.createElement("video");
            video.src = URL.createObjectURL(file);

            video.onloadedmetadata = async () => {
                const minWidth = 320;
                const maxWidth = 1920;
                const minHeight = 180;
                const maxHeight = 1080;

                if (
                    video.videoWidth < minWidth || video.videoWidth > maxWidth ||
                    video.videoHeight < minHeight || video.videoHeight > maxHeight
                ) {
                    dispatch({
                        type: 'SET_ALERT_MESSAGE',
                        payload: {
                            type: "warning",
                            message: `Video dimensions must be between ${minWidth}x${minHeight} and ${maxWidth}x${maxHeight} pixels.`
                        }
                    });
                    return;
                }

                processFile(file, items, contentType);
            };

            video.onerror = () => {
                dispatch({
                    type: 'SET_ALERT_MESSAGE',
                    payload: {
                        type: "warning",
                        message: "Error loading video. Please try another file."
                    }
                });
            };
        } else if (type === "post" && isImage) {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = async () => {
                const isValidResolution = contentType === "story"
                    ? img.width <= 1080 && img.height <= 1920
                    : img.width >= 320 && img.width <= 1080;

                if (file.type === "application/pdf") {
                    dispatch({
                        type: 'SET_ALERT_MESSAGE',
                        payload: { type: "warning", message: "PDF files are not allowed." }
                    });
                    return;
                }

                if (!isValidResolution) {
                    dispatch({
                        type: 'SET_ALERT_MESSAGE',
                        payload: {
                            type: "warning",
                            message: contentType === "story"
                                ? "Image resolution must be 1080 x 1920 pixels or smaller."
                                : "Image width must be between 320 and 1080 pixels."
                        }
                    });
                    return;
                }
                processFile(file, items, contentType);
            };
        } else {
            dispatch({
                type: 'SET_ALERT_MESSAGE',
                payload: { type: "warning", message: "Invalid file type. Please upload an image or video." }
            });
        }
    };
    const [selectedPlaylists, setSelectedPlaylists] = useState({});
    const handlePlaylistsSelected = (selected) => {
        console.log(selected)
        const selectedPlaylistDetails = selected.filter((playlist) =>
            selected.includes(playlist.playlist_id)
        );
        setSelectedPlaylists({
            for_api: selectedPlaylistDetails,
            for_view: selected
        });
    }
    console.log("composePlaylist", selectedPlaylists)
    const commonCompose = async (data) => {
        setIsLoading(true);
        // return
        try {
            const { selectedPostType, uploads } = state;


            // Validation for required uploads based on post type
            if (selectedPostType && selectedPostType !== "text" && !uploads[selectedPostType]?.file) {
                dispatch({
                    type: 'SET_ALERT_MESSAGE',
                    // payload: { type: "warning", message: `${selectedPostType} preview is required` },
                    payload: { type: "warning", message: `To proceed, upload the required file.` },
                });

                return; // Prevent further execution or form submission
            } else {
                data.channel_id = data.where_to_post.map((item) => item.value);  //looping the platform ids
                data.image_data = uploads[selectedPostType]?.file; //setting the base64 data of content
                const { where_to_post, ...payload } = data;

                console.log("commonCompose", data, payload)
                // return
                const response = await commonComposeAPI(payload); //use this
                if (response?.data?.error_code === 200 || response?.data?.error_code === 201) {
                    const successMessage = response.data.message || "Posted successfully!";
                    triggerAlert("success", "Success", successMessage);
                    handleComposeClose();
                    pageReload();
                } else {
                    triggerAlert("error", "Error", "Failed to post the content.");
                    handleComposeClose();
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                triggerAlert("error", "Error", "Failed to upload the data.");
            } else {
                triggerAlert("error", "Oops...", "Something went wrong while posting.");
            }
            handleComposeClose();
        } finally {
            setIsLoading(false);
        }
    };

    const [selectedFile, setSelectedFile] = useState({});
    const fileInputRef2 = useRef(null);

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];

        if (file) {
            // Validate file size (less than 2MB)
            if (file.size > 2 * 1024 * 1024) {
                dispatch({
                    type: 'SET_ALERT_MESSAGE',
                    payload: { type: "warning", message: "Thumbnail size must be less than 2MB" }
                });
                return;
            }

            // Validate file format (JPG, PNG, or GIF)
            const allowedFormats = ["image/jpeg", "image/png"];
            if (!allowedFormats.includes(file.type)) {
                dispatch({
                    type: 'SET_ALERT_MESSAGE',
                    payload: { type: "warning", message: "Only JPG or PNG formats are allowed" }
                });
                return;
            }

            // Validate file resolution (1280x720 pixels, 16:9 ratio)
            const image = new Image();
            image.onload = async () => {
                if (image.width !== 1280 || image.height !== 720) {
                    dispatch({
                        type: 'SET_ALERT_MESSAGE',
                        payload: { type: "warning", message: "Image must be 1280 by 720 pixels (16:9 ratio)" }
                    });
                    return;
                }

                // If all validations pass, create a preview and proceed
                const preview = await getBase64(file);
                setSelectedFile({
                    file: file,
                    preview: preview,
                });
                console.log("Selected file:", file);
            };
            image.src = URL.createObjectURL(file);
        }
    };


    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <li className="nav-item">
                <a className="d-flex align-items-center" onClick={handleShowComposeModal}>
                    <button type="button" className="btn btn-primary">
                        <i className="fa fa-pencil" aria-hidden="true"></i> Compose
                    </button>
                </a>
            </li>

            <Modal show={showComposeModal} onHide={handleComposeClose} backdrop="static" size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Compose</Modal.Title>
                </Modal.Header>
                <form id="creditCardForm"
                    className="g-3 fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container"
                    novalidate="novalidate" onSubmit={handleSubmit(commonCompose)}>
                    <Modal.Body>
                        {isLoading && (
                            <div className='loader-overlay text-white'>
                                <Loader />
                            </div>
                        )}
                        <div className="row align-items-center">
                            <div className="form-group col-md-4">
                                <label className="form-label">Where to Post</label>
                                <Controller
                                    name="where_to_post"
                                    {...register('where_to_post',
                                        { required: 'Where to post is required' }
                                    )}
                                    control={control}
                                    render={({ field }) => (
                                        <MultiSelectStatic
                                            {...field}
                                            options={whereToPost}
                                            value={field.value}
                                            onSelect={handleWhereToPost}
                                            placeholder="Choose where to post"

                                        />
                                    )}
                                />


                                {errors.where_to_post && (
                                    <div
                                        style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {errors.where_to_post.message}
                                    </div>
                                )}

                            </div>
                            {/* Post Type Dropdown */}
                            <div className="form-group col-md-4">
                                <label className="form-label">Post Type</label>
                                <select
                                    className="form-select"
                                    name="post_type"
                                    {...register("post_type", {
                                        required: "Post Type is required",
                                    })}
                                    onChange={handlePostType}
                                >
                                    <option value="" selected hidden>Select Post Type</option>
                                    {allowedPostTypes.length > 0 ? (
                                        allowedPostTypes.map((type) => (
                                            <option key={type} value={type}>
                                                {type.charAt(0).toUpperCase() + type.slice(1)}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>
                                            Select a platform first
                                        </option>
                                    )}
                                </select>
                                {errors.post_type && (
                                    <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                                        {errors.post_type.message}
                                    </div>
                                )}
                            </div>
                            <div class="col-md-4">
                                <div id='selected-platform'>
                                    <ul class=" d-flex ms-4 list-inline justify-content-center">
                                        {state.selectedPlatform?.map((platform) => (
                                            <li key={platform.value} className="pe-3">
                                                <a
                                                    href="#!"
                                                    className={state.activePlatform === platform.value ? "active" : ""}
                                                    onClick={() => handleActivePlatform(platform.value)} // Set active platform
                                                >
                                                    <img
                                                        src={platform.icon} // Dynamically load icon based on selection
                                                        className="img-fluid rounded"
                                                        alt={platform.label}
                                                        loading="lazy"
                                                    />
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <div className="row mb-4">
                            <div className="col-md-8">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div className="user-img">
                                            <img src="/assets/images/icon-7797704_1280.png" alt="userimg" className="rounded-circle img-fluid" loading="lazy" width="40" />
                                        </div>
                                        <p className="px-2 mt-3 fw-bold">Vitel global</p>
                                    </div>
                                    {state.activePlatform === 3 && (
                                        <div className="post-text ms-5 mb-4 position-relative ">
                                            {/* <label className="form-label">Title</label> */}

                                            <input
                                                type='text'
                                                className="form-control"
                                                {...register("title", {
                                                    required: "Title is required",
                                                    maxLength: {
                                                        value: 100,
                                                        message: "Title cannot exceed 100 characters.",
                                                    },
                                                })}
                                                placeholder='Add a Title that describes your video'
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        setValue("title", e.target.value, { shouldValidate: true }); // Ensure validation updates
                                                    }
                                                }}
                                            />
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    {errors.title && (
                                                        <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                                                            {errors.title.message}
                                                        </div>
                                                    )}
                                                </div>
                                                <span>
                                                    {watch("title") ? watch("title")?.length : 0} / 100
                                                </span>
                                            </div>

                                        </div>
                                    )}
                                    <div className="post-text ms-5 mb-4 position-relative">
                                        {state.selectedPostType !== 'story' && (
                                            <textarea
                                                className="form-control"
                                                id="exampleFormControlTextarea1"
                                                rows={7}
                                                data-gramm="false"
                                                placeholder={`Tell viewers about your post`}
                                                name="caption"
                                                ref={textareaRef}
                                                {...register("caption", {
                                                    required: "Caption is required",
                                                    maxLength: {
                                                        value: platformLimits[state.activePlatform], // Dynamically set maxLength based on activePlatform
                                                        message: `Caption cannot exceed ${platformLimits[state.activePlatform]} characters.`,
                                                    },
                                                })}
                                                // value={captionText}
                                                onChange={(e) => {
                                                    const maxLength = platformLimits[state.activePlatform]; // Get the current maxLength based on activePlatform

                                                    if (e.target.value.length <= maxLength) {
                                                        setValue("caption", e.target.value, { shouldValidate: true }); // Ensure validation updates
                                                    }
                                                }}
                                            ></textarea>
                                        )}

                                        {/* Wrapper for error message and character count */}
                                        {state.selectedPostType !== 'story' && (
                                            <div
                                                className="d-flex justify-content-between align-items-center mt-1"
                                                style={{
                                                    minHeight: '20px', // Prevent shifting when error message appears
                                                }}
                                            >
                                                <div>
                                                    {errors.caption && (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontSize: "14px",
                                                                marginRight: "10px", // Space between error and character count
                                                            }}
                                                        >
                                                            {errors.caption.message}
                                                        </span>
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "14px",
                                                        color: "#555", // Optional: Customize color for character count
                                                    }}
                                                >
                                                    {state.activePlatform && (
                                                        <>
                                                            {watch("caption")?.length} / {platformLimits[state.activePlatform]}
                                                        </>
                                                    )}{/* Dynamically display character count */}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="d-flex align-items-center ms-5 position-relative">
                                        {/* Image Upload Button */}
                                        {(state.selectedPostType === 'post' || state.selectedPostType === 'story') && (<a href="#/" className="me-2" onClick={handleIconClick}>
                                            <button className="btn btn-soft-primary" type='button'>
                                                <i className="fa fa-picture-o fs-5" aria-hidden="true"></i>
                                            </button>
                                        </a>
                                        )}

                                        {/* Video Upload Button */}
                                        {(state.selectedPostType === 'reel' || state.selectedPostType === 'video') && (
                                            <a href="#/" className="me-2" onClick={handleVideoClick}>
                                                <button className="btn btn-soft-primary" type='button'>
                                                    <i className="fa fa-file-video-o fs-5" aria-hidden="true"></i>
                                                </button>
                                            </a>
                                        )}

                                        <a href="#/" class="me-2 " onClick={() => handleShowEmojis(state?.showEmojisReaction)}>
                                            <button class=" btn btn-soft-primary" type='button'><i class="fa fa-smile-o fs-5" aria-hidden="true"></i></button>
                                        </a>


                                        {/* Hidden file input for images */}
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileChange(e, 'post')}
                                        />

                                        {/* Hidden file input for videos */}
                                        <input
                                            type="file"
                                            ref={fileVideoRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileChange(e, 'video')}
                                        />
                                    </div>
                                    {state?.showEmojisReaction && (
                                        <div className='mt-3'
                                            style={{
                                                position: "absolute", zIndex: 1000, bottom: '34px', left: '11em', backgroundColor: "#fff", overflowY: "auto",
                                                maxHeight: "300px"
                                            }}
                                        >

                                            <Emojis
                                                reaction={true}
                                                allowExpand={false}
                                                onEmojiSelect={
                                                    handleReactionSelect
                                                }
                                                pickerSize={{ height: 288, width: 450 }}
                                            />
                                        </div>
                                    )}

                                    {state.activePlatform === 3 && (
                                        <div className="d-flex justify-content-evenly ms-5 mt-4">
                                            {/* <label className="form-label">Thumbnail</label> */}
                                            {!Object.keys(selectedFile)?.length ? (
                                                <div className="text-center" type='button'>
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        onChange={handleFileSelect}
                                                        className="d-none"
                                                        accept="*/*"
                                                    />
                                                    <div
                                                        onClick={handleUploadClick}
                                                        className="upload-area p-4 border-dashed rounded d-inline-block"
                                                    >
                                                        <div className="mb-2">
                                                            <svg className="upload-icon" width="32" height="32" viewBox="0 0 24 24">
                                                                <path
                                                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    strokeWidth="2"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div className="text-center">
                                                            <span className="text-muted">Upload thumbnail</span>



                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (

                                                <div
                                                    className='d-inline-block border-dashed rounded position-relative'

                                                >
                                                    <img src={selectedFile?.preview} alt='thumbnail'
                                                        style={{ height: '7.2rem', width: '10rem', objectFit: 'contain' }} />
                                                    {/* <p className="small text-muted mt-2 mb-0">
                                                        {selectedFile?.file?.name}
                                                    </p> */}
                                                    <button
                                                        onClick={() => setSelectedFile({})}
                                                        style={{
                                                            position: "absolute",
                                                            top: "-10px",
                                                            right: "-10px",
                                                            background: "black",
                                                            color: "white",
                                                            width: "20px",
                                                            height: "20px",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            borderRadius: "50%",
                                                        }}
                                                    >
                                                        &times;
                                                    </button>
                                                </div>

                                            )}
                                            {selectedPlaylists?.for_view && selectedPlaylists?.for_view.length > 0 ? (

                                                <div className='rounded border-dashed p-5 position-relative'>
                                                    {selectedPlaylists.for_view.length === 1 ? (
                                                        selectedPlaylists.for_view.map((item) => (
                                                            <span key={item.playlist_id} >{item.title}</span> // Access `title` instead of `name`
                                                        ))
                                                    ) : (
                                                        <span >{selectedPlaylists.for_view.length} Playlists</span>
                                                    )}
                                                    <button
                                                        onClick={() => setSelectedPlaylists({})}
                                                        style={{
                                                            position: "absolute",
                                                            top: "-10px",
                                                            right: "-10px",
                                                            background: "black",
                                                            color: "white",
                                                            width: "20px",
                                                            height: "20px",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            borderRadius: "50%",
                                                        }}
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="text-center" type="button">
                                                    <div
                                                        className="upload-area p-4 border-dashed rounded d-inline-block"
                                                        onClick={handleOpenModal}
                                                    >
                                                        <div className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="32" height="32" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#555770" stroke="none">
                                                                    <path d="M557 3825 c-82 -28 -126 -100 -127 -201 0 -82 30 -139 95 -179 l48 -30 1206 -3 c874 -2 1221 1 1261 9 131 27 197 174 136 305 -25 53 -58 83 -115 102 -54 19 -2453 16 -2504 -3z" />
                                                                    <path d="M545 2753 c-78 -41 -115 -103 -115 -193 0 -89 37 -152 112 -191 36 -18 78 -19 1270 -19 1175 0 1235 1 1273 19 154 69 154 313 0 382 -38 18 -98 19 -1275 18 -1107 0 -1238 -1 -1265 -16z" />
                                                                    <path d="M3757 2545 c-50 -17 -85 -50 -108 -100 -16 -35 -19 -68 -19 -282 l0 -243 -232 0 c-130 0 -250 -5 -270 -10 -85 -24 -137 -102 -138 -206 0 -82 30 -139 95 -179 l48 -30 249 -3 248 -4 0 -242 c0 -235 1 -244 23 -287 15 -29 40 -54 71 -74 41 -25 58 -29 116 -29 58 0 75 4 116 29 31 20 56 45 71 74 22 43 23 52 23 287 l0 242 248 4 249 3 48 30 c65 40 95 97 95 179 -1 104 -53 182 -138 206 -20 5 -140 10 -269 10 l-233 0 0 243 c0 213 -3 247 -19 282 -43 97 -163 140 -274 100z" />
                                                                    <path d="M582 1700 c-94 -22 -152 -102 -152 -206 1 -102 53 -180 138 -204 53 -14 1583 -14 1637 1 61 17 120 82 134 148 23 107 -15 197 -104 245 -40 21 -43 21 -830 23 -434 1 -805 -2 -823 -7z" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div className="text-center">
                                                            <span className="text-muted">Add video to playlist</span>
                                                        </div>
                                                    </div>
                                                </div>


                                                // {/* {errors.title && (
                                                //     <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                                                //         {errors.title.message}
                                                //     </div>
                                                // )} */}
                                            )}





                                        </div>
                                    )}


                                    {/* Render Playlist Modal */}
                                    {isModalOpen &&
                                        <PlaylistModal
                                            isOpen={isModalOpen}
                                            onClose={handleCloseModal}
                                            // playlists={playlists}
                                            onPlaylistsSelected={handlePlaylistsSelected}
                                        />}



                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card h-100 border-dashed mb-0">
                                    <div className="card-header">
                                        <div className="header-title">
                                            <h5 className="card-title">Preview</h5>
                                        </div>
                                    </div>
                                    <div className="card-body h-100 w-100">
                                        {
                                            (mediaFromCompose || state.uploads.post || state.uploads.video || state.uploads.story || state.uploads.reel || state.selectedPostType === "text") ? (
                                                <SocialContent
                                                    upload={
                                                        mediaFromCompose
                                                            ? mediaFromCompose
                                                            : state.selectedPostType === "post"
                                                                ? state.uploads.post
                                                                : state.selectedPostType === "reel"
                                                                    ? state.uploads.reel
                                                                    : state.selectedPostType === "story"
                                                                        ? state.uploads.story
                                                                        : state.selectedPostType === "video"
                                                                            ? state.uploads.video
                                                                            : state.selectedPostType === "text"
                                                                                ? { content_type: "text" }
                                                                                : null
                                                    }
                                                    userData={userData}
                                                    watch={watch("caption")}
                                                    truncateName={truncateName}
                                                    selectedPlatform={state.activePlatform} // Pass the selected platform as a prop
                                                />
                                            ) : <span className='h-100 d-flex align-items-center'>Select the desired platform to preview your content.</span>
                                        }
                                    </div>
                                </div>
                                {state.alertMessage && (
                                    <div className="mt-1">
                                        <div className={`alert p-1 alert-${state.alertMessage?.type}`} role="alert">
                                            <div className="d-flex align-items-start mt-2 ">
                                                <div className="me-2">
                                                    {state.alertMessage?.type === 'warning' && (
                                                        <span
                                                            className="material-symbols-outlined"
                                                            style={{ fontSize: "24px", color: "#000" }}
                                                        >
                                                            error
                                                        </span>
                                                    )}
                                                    {state.alertMessage?.type === 'primary' && (
                                                        <span
                                                            className="material-symbols-outlined"
                                                            style={{ fontSize: "24px", color: "#000" }}
                                                        >
                                                            info
                                                        </span>
                                                    )}
                                                </div>
                                                <div>
                                                    <div style={{ color: "black", fontSize: "14px" }}>
                                                        {state.alertMessage?.message}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>


                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="row align-items-center w-100">
                            <div className="form-group col-md-3">
                                <label className="form-label">Add Labels</label>
                                <select
                                    className="form-select"
                                    name="labels"
                                    {...register("labels", {
                                        required: "Labels is required",
                                    })}
                                    defaultValue="1" // Default value should be "1" for the payload
                                >
                                    <option value="" hidden>Select Label</option>
                                    <option value="1">Advertising</option>
                                    <option value="2">Announcement</option>
                                    <option value="3">Marketing</option>
                                    <option value="4">Educational</option>
                                    <option value="5">Testimonial</option>
                                    <option value="6">Behind-the-Scenes</option>
                                    <option value="7">Event</option>
                                    <option value="8">Product Showcase</option>
                                    <option value="9">Industry News</option>
                                    <option value="10">Community Engagement</option>
                                    <option value="11">Inspirational</option>
                                    <option value="12">FAQ</option>
                                    <option value="13">Promotional</option>
                                    <option value="14">Research/Insights</option>
                                    <option value="15">Sustainability</option>
                                    <option value="16">Collaboration</option>
                                    <option value="17">Celebration</option>
                                </select>
                                {errors.labels && (
                                    <div
                                        style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {errors.labels.message}
                                    </div>
                                )}
                            </div>
                            <div className="form-group col-md-3">
                                <label className="form-label">Choose a Posting Action</label>
                                <select className="form-select"
                                    name="action"
                                    {...register("action", {
                                        // required: "Where to Post is required",
                                    })}
                                >
                                    <option value="" hidden>Select</option>
                                    {/* <option value="2">Save in Calendar</option> */}
                                    <option value="1" selected>Publish</option>
                                    <option value="2">Save to Draft (Feed)</option>
                                    <option value="3">Schedule</option>
                                    <option value="4">Send for approval</option>
                                </select>
                                {errors.action && (
                                    <div
                                        style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {errors.action.message}
                                    </div>
                                )}
                            </div>
                            {watch("action") === "3" && (
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className="form-label">When to Post</label>
                                        <Controller
                                            name="when_to_post" // Ensure this name matches your form structure
                                            control={control} // Pass control here
                                            render={({ field }) => (
                                                <>
                                                    <DatePicker
                                                        selected={field.value ? new Date(field.value) : new Date()} // Default to today's date if field.value is empty
                                                        onChange={(date) => handleDateChange(date, field)}
                                                        showTimeSelect
                                                        timeFormat="hh:mm aa" // AM/PM format
                                                        timeIntervals={15}
                                                        dateFormat="dd-MM-yyyy hh:mm aa" // Include AM/PM in date format
                                                        placeholderText="DD-MM-YYYY HH:MM"
                                                        className={`px-3 form-control ${watch('when_to_post') && field.error ? 'is-invalid' : ''}`}
                                                        minDate={new Date()} // Ensure the minimum date is today
                                                        todayButton="Today" // Button to quickly select today's date
                                                    />
                                                    {field.error && <div className="invalid-feedback">{field.error.message}</div>}

                                                </>
                                            )}
                                        />
                                        {errors.when_to_post?.type === 'manual' && (
                                            <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                                {errors.when_to_post.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {watch("action") === "4" && (
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label className="form-label">Sub User</label>
                                        <select
                                            className="form-select"
                                            name="sub_user" // Ensure no extra spaces in the name attribute
                                            {...register("sub_user", {
                                                required: "Sub user is required when sending for approval",
                                            })}
                                        >
                                            <option value="" hidden>Select Sub User</option>
                                            {subUser.map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.user_email}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.sub_user && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    fontSize: "14px",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                {errors.sub_user.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="col-md-3">
                                <button type="submit" className="btn btn-primary mt-3 px-3">Save</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal >
            <ShowComposeModal
                show={showMediaModal}
                onHide={handleMediaModalClose}
                onMediaSelect={handleAddToCompose}
            />
        </>
    )
}
